import { ROUTES } from "../../enums/routes";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { weweContextActions } from "../../../app/slice/weweSlice";
import { useAppDispatch } from "../../../app/hooks/store";
import LayoutContext from "../../layout/layoutContext";
import WeweLogoSvg from "../wewe/weweLogoSvg";
import { useWeweNavigateLong } from "../../../app/others/useWeweNavigation";

function MainMenuComponent() {

    const isDesktop = useContext(LayoutContext);
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();
    const arr_services = [ROUTES.SERVICES_BRANDIDENTITY, ROUTES.SERVICES_PERSONALCONSULTING, ROUTES.SERVICES_VISUALDESIGN, ROUTES.SERVICES_WEBDEVELOPMENT];
    const arr_porftolio = [ROUTES.PORTFOLIO_WEWEANIMATION, ROUTES.PORTFOLIO_WEWELOGO, ROUTES.PORTFOLIO_WEWEWEBSITE, ROUTES.PORTFOLIO_GIGGLELOGO, ROUTES.PORTFOLIO_HURAAALOGO];
    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = useWeweNavigateLong();

    return (
        isDesktop
            ? <div id="wewe-navbar">{getDesktopLayout()}</div>
            : <div id="wewe-navbar-mobile">{getMobileLayout()}</div>
    )

    function getMobileLayout() {
        return <div id="mobile-header">
            <div className="mobil-header">
                <div className="header-mobil-child">
                    <a href={`/${ROUTES.LANDING}`} className="a-reset" rel="noopener noreferrer" key={ROUTES.LANDING} onClick={handleClick}>
                        <div className="wewe-logo1">
                            <WeweLogoSvg classname="wewe-26" isBlack={true} />
                        </div>
                    </a>
                    <div className="menu-pictogram1" onClick={() => dispatch(weweContextActions._showPopupMenu(true))}>
                        <div className="rectangle-1157-21" />
                        <div className="rectangle-1157-21" />
                    </div>
                    <div className="sahi-hi-button1" onClick={() => dispatch(weweContextActions._showPopupSayHi(true))}>
                        <b className="wewe-26">SAY HI</b>
                    </div>
                </div>
            </div>
        </div>
    }

    function getDesktopLayout() {
        return <div id="desktop-header">
            <div className="desktop-header-2">
                <a href={`/${ROUTES.LANDING}`} className="a-reset" rel="noopener noreferrer" key={ROUTES.LANDING} onClick={handleClick}>
                    <div className="frame-parent">
                        <div className="wewe-wrapper">
                            <WeweLogoSvg classname="wewe-icon" isBlack={true} />
                        </div>
                        <div className="header-claim-logo">
                            <div className="slovak-visual-design-container">
                                <p className="slovak-visual">SLOVAK VISUAL</p>
                                <p className="slovak-visual">DESIGN STUDIO</p>
                            </div>
                        </div>
                    </div>
                </a>
                <div className="button-5-2-parent">
                    <nav id="mainmenu">
                        <NavLink to={ROUTES.STORY} className={({ isActive }) => `${isActive ? " active " : ""} `}>STORY</NavLink>
                        <NavLink to={ROUTES.SERVICES} className={({ isActive }) => `${isActive || arr_services.some(d => d === pathname) ? " active " : ""} `}>SERVICES</NavLink>
                        <NavLink to={ROUTES.PORTFOLIO} className={({ isActive }) => `${isActive || arr_porftolio.some(d => d === pathname) ? " active " : ""} `}>PORTFOLIO</NavLink>
                    </nav>
                    <div className="button-5-2" onClick={() => dispatch(weweContextActions._showPopupSayHi(true))}>
                        <b className="slovak-visual-design-container portfolio">
                            SAY HI
                        </b>
                    </div>
                </div>
            </div>
        </div>
    }

    
}

const Navbar = MainMenuComponent;
export default Navbar;