import { useLocation, useNavigate } from "react-router";
import { ROUTES } from '../../enums/routes';
import { useContext } from 'react';
import LayoutContext from "../../layout/layoutContext";
import WeweLogoSvg from "../wewe/weweLogo";
import { useAppDispatch } from "../../../app/hooks/store";
import { useWeweNavigateLong } from "../../../app/others/useWeweNavigation";

function FooterComponent() {

    const isDesktop = useContext(LayoutContext);

    const handleClick = useWeweNavigateLong();

    const handleCookieSettings = () => {
        if (window.Cookiebot) {
            window.Cookiebot.renew();
        } 
    };

    return (
        isDesktop ? getDesktopLayout() : getMobileLayout()
    )

    function getMobileLayout() {
        return <div id="mobile-footer">
            <div className="mobil-landing-11">
                <div className="frame-2917-11">
                    <div className="frame-2916-11">
                        <div className="frame-2821-11">
                            <a href={`${ROUTES.SERVICES}`} className="a-reset" rel="noopener noreferrer" key={ROUTES.SERVICES} onClick={handleClick}>
                                <b className="services-11">services</b>
                            </a>
                            <a href={`${ROUTES.PORTFOLIO}`} className="a-reset" rel="noopener noreferrer" key={ROUTES.PORTFOLIO} onClick={handleClick}>
                                <b className="portfolio-11">portfolio</b>
                            </a>
                            <a href={`${ROUTES.CONTACT}`} className="a-reset" rel="noopener noreferrer" key={ROUTES.CONTACT} onClick={handleClick}>
                                <b className="services-11">contact</b>
                            </a>
                            <a href={`${ROUTES.STORY}`} className="a-reset" rel="noopener noreferrer" key={ROUTES.STORY} onClick={handleClick}>
                                <b className="story-11">story</b>
                            </a>
                            <a className="a-reset" rel="noopener noreferrer" key="cookies" onClick={handleCookieSettings}>
                                <b className="story-11">cookies</b>
                            </a>
                        </div>
                    </div>
                    <a href={`/${ROUTES.LANDING}`} className="a-reset" rel="noopener noreferrer" key={ROUTES.LANDING} onClick={handleClick}>
                        <div className="mobil-footer-logo-animacia-2-2">
                            <WeweLogoSvg classname="" isBlack={false} />
                        </div>
                    </a>
                </div>
            </div>
        </div>;
    }

    function getDesktopLayout() {
        return <div id="desktop-footer">
            <div className="footer-2 frame-18-2-parent">
                {/* TOP */}
                <div className="frame-18-2">
                    {/* LEFT */}
                    <div className="frame-2961-2">
                        <div className="footer-services-button1">
                            <a href={`${ROUTES.SERVICES}`} className="a-reset" rel="noopener noreferrer" key={ROUTES.SERVICES} onClick={handleClick}>
                                <b className="our-story-5-22">services</b>
                            </a>
                        </div>
                        <div className="footer-portfolio-button1">
                            <a href={`${ROUTES.PORTFOLIO}`} className="a-reset" rel="noopener noreferrer" key={ROUTES.PORTFOLIO} onClick={handleClick}>
                                <b className="our-story-21">portfolio</b>
                            </a>
                        </div>
                        <div className="footer-contact-button1">
                            <a href={`${ROUTES.CONTACT}`} className="a-reset" rel="noopener noreferrer" key={ROUTES.CONTACT} onClick={handleClick}>
                                <b className="our-story-21">contact</b>
                            </a>
                        </div>
                    </div>
                    {/* RIGHT */}
                    <div className="frame-2961-2">
                        <div className="footer-services-button1">
                            <a href={`${ROUTES.STORY}`} className="a-reset" rel="noopener noreferrer" key={ROUTES.STORY} onClick={handleClick}>
                                <b className="our-story-21">story</b>
                            </a>
                        </div>
                        <div className="footer-services-button1">
                            <a className="a-reset" rel="noopener noreferrer" key="cookies" onClick={handleCookieSettings}>
                                <b className="our-story-21">cookies</b>
                            </a>
                        </div>
                    </div>
                </div>
                {/* BOTTOM  >>  wewe ? . " ! */}
                <a href={`/${ROUTES.LANDING}`} className="a-reset" rel="noopener noreferrer" key={ROUTES.LANDING} onClick={handleClick}>
                    <div className="logo-animations">
                        <WeweLogoSvg classname="footer-wewe-icon" isBlack={false} />
                    </div>
                </a>
            </div>
        </div>
    }
}

const Footer = FooterComponent;
export default Footer;

function dispatch(arg0: (dispatch: import("@reduxjs/toolkit").ThunkDispatch<any, undefined, import("@reduxjs/toolkit").UnknownAction> & import("@reduxjs/toolkit").Dispatch<import("@reduxjs/toolkit").UnknownAction>) => Promise<void>) {
    throw new Error("Function not implemented.");
}
