import { FunctionComponent, useContext } from "react";
import { useAppDispatch } from "../../../app/hooks/store";
import { weweContextActions } from "../../../app/slice/weweSlice";
import { NavLink, useLocation } from "react-router-dom";
import { ROUTES } from "../../enums/routes";
import LayoutContext from "../../layout/layoutContext";

const MenuComponent: FunctionComponent = () => {

    const dispatch = useAppDispatch();
    const isDesktop = useContext(LayoutContext);
    const { pathname } = useLocation();
    const arr_services = [ROUTES.SERVICES_BRANDIDENTITY, ROUTES.SERVICES_PERSONALCONSULTING, ROUTES.SERVICES_VISUALDESIGN, ROUTES.SERVICES_WEBDEVELOPMENT];
    const arr_porftolio = [ROUTES.PORTFOLIO_WEWEANIMATION, ROUTES.PORTFOLIO_WEWELOGO, ROUTES.PORTFOLIO_WEWEWEBSITE, ROUTES.PORTFOLIO_GIGGLELOGO, ROUTES.PORTFOLIO_HURAAALOGO ];

    return (
        isDesktop ? <></>
            : <div id="mobile-menu">
                <div className="mobil-menu">
                    <div className="frame-2821-2-parent">
                        <div className="frame-2821-2">
                            <nav id="mainmenu-mobile" >
                                <NavLink to={ROUTES.SERVICES} className={({ isActive }) => `${isActive || arr_services.some(d => d === pathname) ? " active " : ""} `}>services</NavLink>
                                <NavLink to={ROUTES.PORTFOLIO} className={({ isActive }) => `${isActive || arr_porftolio.some(d => d === pathname)? " active " : ""} `}>portfolio</NavLink>
                                <NavLink to={ROUTES.CONTACT} className={({ isActive }) => ` ${isActive ? " active " : ""} `}>contact</NavLink>
                                <NavLink to={ROUTES.STORY} className={({ isActive }) => ` ${isActive ? " active " : ""} `}>story</NavLink>
                                <NavLink to={ROUTES.LANDING} className={({ isActive }) => ` ${isActive ? " active " : ""} `}>home</NavLink>
                            </nav>
                        </div>
                        <div className="header-mobil-2">
                            <div className="header-mobil-2-inner">
                                <div className="rectangle-1157-3-parent" onClick={() => dispatch(weweContextActions._showPopupMenu(false))}>
                                    <div className="rectangle-1157-3" />
                                    <div className="rectangle-1158-3" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    );
};

const Menu = MenuComponent;
export default Menu;